import { Center, Heading } from '@chakra-ui/react'
import React from 'react'

const NotFoundPage = () => (
  <Center>
    <Heading>NOT FOUND</Heading>
  </Center>
)

export default NotFoundPage
